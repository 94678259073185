import React, { useState, useEffect } from 'react';
import './App.css'; // Imports the styles below

// Array of images and information for the carousel
const slides = [
  {
    src: './assets/image1.jpg',
    heading: 'Welcome to AGT-IT Solutions',
    description: 'Your go-to place for exceptional IT services. We combine innovation and expertise to empower your business with tailored solutions that drive success in a digital world. Experience the AGT-IT difference today!',
    buttonText: 'Get Started'
  },
  {
    src: './assets/image2.jpg',
    heading: 'Quality Services',
    description: 'At AGT-IT Solutions, we deliver exceptional IT services tailored to your needs. Our expert team focuses on quality, reliability, and innovation, ensuring your projects succeed in a competitive landscape. Experience excellence with us!',
    buttonText: 'Learn More'
  },
  {
    src: './assets/image3.jpg',
    heading: 'Expert Team',
    description: 'Our expert team at AGT-IT Solutions combines years of industry experience and diverse skills to deliver innovative IT solutions. Dedicated and passionate, we work collaboratively to understand your needs and drive your success.',
    buttonText: 'Meet the Team'
  },
  {
    src: './assets/image4.jpg',
    heading: 'Innovative Solutions',
    description: 'At AGT-IT Solutions, we harness the latest technologies to create innovative solutions that propel your business forward. Our approach combines creativity and expertise, ensuring you stay ahead in a rapidly changing digital landscape.',
    buttonText: 'Discover More'
  },
  {
    src: './assets/image5.jpg',
    heading: 'Customer Satisfaction',
    description: 'At AGT-IT Solutions, your satisfaction is our top priority. We strive to exceed expectations through exceptional service, open communication, and a commitment to delivering results that align with your goals.',
    buttonText: 'Contact Us'
  }
];

function Carousel() {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
    }, 8000); // 8000ms = 8 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  const handleDotClick = (index) => {
    setCurrentIndex(index);
  };

  const handleArrowKey = (e) => {
    if (e.key === 'ArrowRight') {
      setCurrentIndex((currentIndex + 1) % slides.length);
    } else if (e.key === 'ArrowLeft') {
      setCurrentIndex((currentIndex - 1 + slides.length) % slides.length);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleArrowKey);
    return () => {
      window.removeEventListener('keydown', handleArrowKey);
    };
  }, [currentIndex]);

  return (
    <div
      className="carousel"
      style={{
        backgroundImage: `url(${slides[currentIndex].src})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100vh', // Fullscreen carousel
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      {/* Logo element */}
      <div
        style={{
          display: 'inline-block',
          padding: '10px',
          backgroundColor: '#1e2a46',
          border: '2px solid #ffa500',
          borderRadius: '10px',
          boxShadow: 'rgba(0, 0, 0, 0.5);',
          marginBottom: '20px',
        }}
      >
        <img
          src="./assets/logo.png"
          alt="AGT-IT Logo"
          style={{
            maxWidth: '280px',
            display: 'block',
          }}
        />
      </div>

      {/* Text Box for heading and description */}
      <div className="carousel-text-box" style={{
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        padding: '20px',
        borderRadius: '10px',
        maxWidth: '80%',
      }}>
        <h1>{slides[currentIndex].heading}</h1>
        <p>{slides[currentIndex].description}</p>
        <button className="btn">{slides[currentIndex].buttonText}</button>
      </div>

      {/* Display dots for manual navigation */}
      <div className="carousel-dots">
        {slides.map((_, index) => (
          <span
            key={index}
            className={`dot ${index === currentIndex ? 'active' : ''}`}
            onClick={() => handleDotClick(index)}
          />
        ))}
      </div>
    </div>
  );
}

export default Carousel;
