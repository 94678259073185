import React, { useState } from 'react';
import './App.css'; // Ensure this imports your styles
import Carousel from './Carousel'; // Import the Carousel component

function App() {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [isServicesOpen, setServicesOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const toggleServices = () => {
    setServicesOpen(!isServicesOpen); // Toggle submenu visibility
  };

  return (
    <div className="App">
      {/* Sidebar */}
      <div className={`sidebar ${isSidebarOpen ? 'active' : ''}`} id="sidebar">
        <div className="sidebar-header">
          <img src="./assets/logo.png" alt="Intrinsic Logo" className="sidebar-logo" />
          <span className="close-btn" onClick={toggleSidebar}>&times;</span>
        </div>
        <ul className="sidebar-menu">
          <li><a href="#">Home</a></li>
          <li><a href="#about">About</a></li>
          <li><a href="#clients">Our Clients</a></li>
          {/* Services menu with subfields */}
          <li className="services-item">
            <a href="#services" onClick={toggleServices}>Services</a>
            <ul className={`submenu ${isServicesOpen ? 'open' : ''}`}>
              <li><a href="#custom-software">Custom Software Development</a></li>
              <li><a href="#cybersecurity">Cybersecurity Solutions</a></li>
              <li><a href="#it-consulting">IT Consulting</a></li>
              <li><a href="#ai">AI and Machine Learning</a></li>
              <li><a href="#gis">GIS & Geospatial Solutions</a></li>
              <li><a href="#data-management">Data Management</a></li>
              <li><a href="#staff-augmentation">Staff Augmentation</a></li>
              <li><a href="#cloud-migration">Cloud Migration & DevOps</a></li>
              <li><a href="#application-development">Application Development</a></li>
              <li><a href="#Fueling-and-Retailing Automation Software">Fueling and Retailing Automation Software</a></li>
              <li><a href="#RPA">Robotic Process Automation (RPA)</a></li>
              <li><a href="#Full-Stack-Development">Full Stack Development</a></li>
            </ul>
          </li>

          <li><a href="#contact">Contact</a></li>
        </ul>
      </div>

      {/* Sidebar Toggle Button */}
      <span className="open-sidebar" onClick={toggleSidebar}>&#9776;</span>

      {/* Hero Section with Carousel */}
      <section className="hero-section">
        <Carousel /> {/* Carousel Component */}
      </section>

      {/* About Section */}
<section id="about" className="about-section">
  <h2>About Us</h2>
  <p>
    AGT-IT Solutions (Advanced Global Technologies IT Solutions) Established in 2024, we transcend the conventional role of a technology service provider; we are ardent innovators passionately dedicated to crafting a vibrant digital future. Founded by a visionary team of seasoned software entrepreneurs, our company merges decades of rich expertise in staff augmentation and IT services across web, mobile, and desktop platforms. Our unwavering commitment to innovation and quality empowers businesses to thrive in a rapidly evolving technological landscape, allowing them to harness the transformative potential of technology.
  </p>
  <br /><br />

  <p>
    With strategically positioned branches in Australia and India, we take immense pride in our diverse and accomplished team, comprising professionals with extensive backgrounds—many with over 10 to 20 years of experience in the industry. This remarkable depth of knowledge enables us to embrace a collaborative approach, ensuring we fully understand our clients' unique challenges, aspirations, and market dynamics. By fostering strong, enduring partnerships, we deliver customized, high-quality solutions that align seamlessly with their specific needs and strategic objectives.
  </p>
  <br /><br />

  <p>
    In an era where technological advancements are reshaping industries, AGT-IT Solutions stands as a beacon for businesses seeking to innovate. Whether you are looking for skilled experts to enhance your existing projects or pursuing cutting-edge technology solutions to transform your operations, we are prepared to support your vision at every stage. Our adaptability allows us to work across various domains, ensuring that we bring the right talent and expertise to each partnership. We recognize that each client is unique, and we tailor our approach to meet their specific requirements, creating solutions that drive both immediate results and long-term success.
  </p>
  <br /><br />

  <p>
    Our holistic approach ensures that we do not just address immediate challenges but also lay the groundwork for sustained growth and success. We believe that true transformation goes beyond technology; it requires a shift in mindset, processes, and culture. At AGT-IT Solutions, we are committed to guiding our clients through this transformation journey, equipping them with the tools and insights necessary to navigate the complexities of the digital landscape.
  </p>
  <br /><br />

  <p>
    As we advance in this exhilarating digital era, we invite you to join us on a journey toward a brighter and more prosperous future. Together, let us explore the limitless possibilities that technology offers, transforming challenges into opportunities and aspirations into remarkable achievements. At AGT-IT Solutions, we are steadfastly committed to shaping your tomorrow, illuminating the path toward a new horizon in the digital landscape.
  </p>
  <br /><br />

  <p>
    In this journey of innovation and collaboration, we recognize the importance of sustainability in our technological endeavors. We are dedicated to developing solutions that not only drive business success but also promote environmental stewardship and social responsibility. As we move forward, our goal is to create a lasting impact that resonates beyond the immediate benefits of our services, contributing positively to society and the planet.
  </p>
  <br /><br />

  <p>
    We are excited about the future and the role we play in it. With every project, we strive to push the boundaries of what is possible, continuously exploring new technologies and methodologies to deliver unparalleled value to our clients. Our commitment to excellence is unwavering, and we invite you to partner with us as we forge a new era of innovation, collaboration, and sustainable growth that resonates through time.
  </p>
</section>

<section id="clients" className="clients-section">
  <h2>Our Clients & Technology Partners</h2>
  <div className="clients-container">
    <div className="client">
      <img src="./assets/download.png" alt="Client 1 Logo" className="client-logo" />
      <p>Fuel Trans</p>
    </div>
    <div className="client">
      <img src="./assets/Elite-fuels-logo-2.png" alt="Client 2 Logo" className="client-logo" />
      <p>Elite-fuels</p>
    </div>
    <div className="client">
      <img src="./assets/IGC.png" alt="Client 3 Logo" className="client-logo" />
      <p>Intrinsic Global Communications</p>
    </div>
    <div className="client">
      <img src="./assets/cmpr.png" alt="Client 4 Logo" className="client-logo" />
      <p>Camphor Info</p>
    </div>
    <div className="client">
      <img src="./assets/Kode.png" alt="Client 5 Logo" className="client-logo" />
      <p>KodeClust Technologies</p>
    </div>
    {/* Add more clients as needed */}
  </div>
</section>



      {/* Services Section */}
<section id="services" className="services-section">
  <h2>Our Services</h2>
  <div className="services-container">
    {/* Service 1 */}
    <div className="service">
      <img src="./assets/custom-software.png" alt="Custom Software" className="service-icon" />
      <h3>Custom Software Development</h3>
      <p>Craft innovative software solutions tailored to your unique business challenges, enhancing efficiency and driving digital transformation.</p>
    </div>
    {/* Service 2 */}
    <div className="service">
      <img src="./assets/cybersecurity.png" alt="Cybersecurity Solutions" className="service-icon" />
      <h3>Cybersecurity Solutions</h3>
      <p>Protect your business with comprehensive cybersecurity services designed to safeguard your data and IT infrastructure against evolving threats.</p>
    </div>
    {/* Service 3 */}
    <div className="service">
      <img src="./assets/it-consulting.png" alt="IT Consulting" className="service-icon" />
      <h3>IT Consulting</h3>
      <p>Partner with our experts to refine your IT strategy, ensuring it aligns with your business goals and leverages the latest technologies for maximum impact.</p>
    </div>
    {/* Service 4 */}
    <div className="service">
      <img src="./assets/ai-ml.png" alt="AI & Machine Learning Solutions" className="service-icon" />
      <h3>AI & Machine Learning Solutions</h3>
      <p>Leverage AI and machine learning to unlock insights from your data, automate processes, and enhance decision-making for a competitive edge.</p>
    </div>
    {/* Service 5 */}
    <div className="service">
      <img src="./assets/gis.png" alt="GIS & Geospatial Solutions" className="service-icon" />
      <h3>GIS & Geospatial Solutions</h3>
      <p>Utilize advanced GIS and geospatial technologies to analyze and visualize data, optimizing decision-making in sectors like urban planning and environmental management.</p>
    </div>
    {/* Service 6 */}
    <div className="service">
      <img src="./assets/data-management.png" alt="Data Management" className="service-icon" />
      <h3>Data Management</h3>
      <p>We ensure the integrity and security of your data through comprehensive governance, integration, and analytics, empowering informed decision-making and enhanced performance.</p>
    </div>
    {/* Service 7 */}
    <div className="service">
      <img src="./assets/staff-augmentation.png" alt="Staff Augmentation" className="service-icon" />
      <h3>Staff Augmentation</h3>
      <p>Fuel your projects with our talented professionals who seamlessly integrate into your teams, providing specialized skills to accelerate your success.</p>
    </div>
    {/* Service 8 */}
    <div className="service">
      <img src="./assets/cloud-migration.png" alt="Cloud Migration & DevOps" className="service-icon" />
      <h3>Cloud Migration & DevOps</h3>
      <p>Streamline your transition to the cloud with our expert migration services, complemented by DevOps practices that enhance collaboration and automate workflows for faster, more reliable software delivery.</p>
    </div>
    {/* Service 9 */}
    <div className="service">
      <img src="./assets/application-development.png" alt="Application Development" className="service-icon" />
      <h3>Application Development</h3>
      <p>We create custom applications for desktop, web, mobile, and tablets, delivering user-friendly solutions that meet your unique business needs and enhance user engagement.</p>
    </div>
      {/* Service 10 */}
    <div className="service">
      <img src="./Images/Icon-colored-line.png" alt="Fueling and Retailing Automation Software" className="service-icon" />
      <h3>Forecourt Controller - Fueling and Retailing Automation Software</h3>
      <p>Our Forecourt Controller streamlines fuel dispensing and retail operations by integrating pumps, POS, and back-office systems. It ensures real-time monitoring, efficient transactions, and easy reporting for smooth and automated fuel station management.</p>
    </div>
     {/* Service 11 */}
     <div className="service">
      <img src="./Images/RPA.png" alt="Robotic Process Automation (RPA)" className="service-icon" />
      <h3>Robotic Process Automation (RPA)</h3>
      <p>We automate repetitive tasks to save time, reduce errors, and improve efficiency. Our RPA solutions streamline processes like data entry and invoicing, helping your business operate faster and smarter.</p>
    </div>
     {/* Service 12 */}
     <div className="service">
      <img src="./Images/fullstack-development.png" alt="Full Stack Development" className="service-icon" />
      <h3>Full Stack Development</h3>
      <p>We provide complete web and mobile app solutions, handling both front-end and back-end development. From intuitive user interfaces to powerful databases, we deliver high-performance, scalable applications tailored to your needs.</p>
    </div>
  </div>
</section>


      {/* Contact Section */}
      <section id="contact" className="contact-section">
        <h2>Contact Us :</h2>
        <div className="contact-container">
          {/* India Contact Details */}
          <div className="contact-details">
            <p><strong>Email:</strong> info@agtitsolutions.com</p>
            <p><strong>India Office:</strong></p>
            <p>
              AGT-IT Solutions, Plot No. 14,<br />
              Defence Colony, Near Laxmi Vihar Phase -1, Nallangandla<br />
              Hyderabad, Telangana - 5000 019.
            </p>
          </div>
          
          {/* Australia Contact Details */}
          <div className="contact-details">
            <p><strong>Email:</strong> info@agtitsolutions.com</p>
            <p><strong>Australia Office:</strong></p>
            <p>
              AGT-IT Solutions,<br />
              19 Trumper Avenue,<br />
              Parafield Gardens, Adelaide, S.A 5107, Australia.
            </p>
          </div>
        </div>

        {/* Google Maps Embed */}
        <div className="map-container-side-by-side">
          <div className="map-container">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d951.3978109413094!2d78.30400465734705!3d17.479267681348368!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb92cf6e300f47%3A0x520bef7c912e09a7!2sDefence%20Colony%2C%20Serilingampalle%20(M)%2C%20Telangana!5e0!3m2!1sen!2sin!4v1727165560031!5m2!1sen!2sin"
              width="600"
              height="450"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>

          <div className="map-container">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d819.1098066276437!2d138.61260146958304!3d-34.7948899983054!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ab0b6e9fcafdb95%3A0x459163559204adaa!2s19%20Trumper%20Ave%2C%20Parafield%20Gardens%20SA%205107%2C%20Australia!5e0!3m2!1sen!2sin!4v1727164717692!5m2!1sen!2sin"
              width="600"
              height="450"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="main-footer">
        <p>&copy; 2024 AGT-IT Solutions Pvt. Ltd. All Rights Reserved.</p>
        <img src="./assets/logo.png" alt="Footer Logo" className="footer-logo" />
      </footer>
    </div>
  );
}

export default App;

